import React, { useState } from "react"
import { Form } from "react-bootstrap"

// Styles
import "../CustomRadioButton/CustomRadioButton.scss"

const CustomRadioButton = ( radioButtonData ) => {

    const { name, label, defaultOption, options = [], onChangeCallback, isInline = true, variant } = radioButtonData;

    const [ selectedOption, setSelectedOption ] = useState( defaultOption );

    const handleOnChange = ( option ) => {

        setSelectedOption( option );
        onChangeCallback && onChangeCallback( name, option );
    }

    return (
        <div className={ `form-field custom-radiobutton-wrapper ${ variant || '' }` }>
            <label className="custom-label" >
                { label }
            </label>
            <Form.Group>
                <div
                    className={ isInline ? 'inline-group' : '' }
                >
                    { options.map( ( optionItem, optionsIndex ) => {
                        return (
                            <Form.Check
                                key={ optionsIndex }
                                inline
                                type="radio"
                                label={ optionItem }
                                name={ name }
                                id={ `inline-${ name }-${ optionsIndex }` }
                                checked={ optionItem === selectedOption }
                                onChange={ () => handleOnChange( optionItem ) }
                                value={optionItem}
                            />
                        )
                    } ) }
                </div>
            </Form.Group>
        </div>
    )
}

export default CustomRadioButton
