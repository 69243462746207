import React, {useState, useEffect} from 'react';

// Components
import CustomTextField from "components/CustomTextField";
import CustomButton from "components/CustomButton";

const Step1 = (props) => {
    return (
        <>
            <div className="form-fields-wrapper">
                <CustomTextField
                    type="text"
                    name="agency_name"
                    label="AGENCY NAME *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="branch_name"
                    label="BRANCH / OFFICE *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="branch_postcode"
                    label="BRANCH / OFFICE POSTCOCDE *"
                    defaultValue=""
                    handlechange={props.handlechange}
                    required={true}
                />
            </div>
            <div>
                <CustomButton
                    variant="green"
                    value="NEXT STEP"
                    type="submit"
                    //onClick={() => props.setSteps('step2')}
                />
            </div>
        </>
    );
};

export default Step1;
