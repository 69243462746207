import React, {useState, useEffect} from 'react';
import { Container, Form } from "react-bootstrap";
import { navigate, Link } from "gatsby";

// Styles
import './RegistrationScreen.scss';

// Components
import { handleRegister } from "../../../services/auth"
import {CustomLinks} from "components/common/menuUtils";
import {RegisterSteps, UserAgents_RoleID} from "components/common/static_seo";
import { phoneNumberValidation } from "components/common/utils"

import Step1 from "./Steps/step1";
import Step2 from "./Steps/step2";
import Step3 from "./Steps/step3";

const RegistrationScreen = () => {
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState('step1');
    const [validated, setValidated] = useState(false)
    const [showerror, setShowerror] = useState(false)
    const [error, setError] = useState("")
    const myRef = React.createRef();

    const [showsuccess, setShowsuccess] = useState(false)
    const [success, setSuccess] = useState('')

    const setStepsFunct = (value) => {
        setSteps(value);
        myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
    }
    const handlechange = event => {
        // remove initial empty spaces
        if(event.target.name === "branch_phone" || event.target.name === "telephone" || event.target.name === "mobile_no"){
          var valid_phone = phoneNumberValidation(event.target.value);
          if(valid_phone){
            event.target.value = valid_phone;
          } else{
            event.target.value = '';
          }
        } else{
          event.target.value = event.target.value.trimStart()
        }
    }

    const handleAuth = async params => {
      const regStatus = await handleRegister(params)

      //console.log("regStatus", regStatus);
      if (regStatus.status === 201 || regStatus.status === 200) {  
        setSuccess("Thanks for registered with agent.");
        setShowsuccess(true); 

        setLoading(false);
        navigate(`/${CustomLinks.login}/`)
          
      } else {
        setLoading(false);
        setError(regStatus.message)
        setShowerror(true)
      }
    }

    const handleSubmit = event => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
          setError("Highlighted fields are required.")

          if(steps === "step1"){
            if(form.agency_name.validationMessage !== "" || form.branch_name.validationMessage !== "" || form.branch_postcode.validationMessage !== ""){
              setShowerror(true)
              setValidated(true)
              myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
            } else{
              setStepsFunct("step2");  
              setShowerror(false);
              setValidated(false);
            }
          } else if(steps === "step2"){
            if(form.branch_address.validationMessage !== "" || form.branch_area.validationMessage !== "" || form.branch_city.validationMessage !== "" || form.branch_phone.validationMessage !== "" || form.branch_email.validationMessage !== ""){
              setShowerror(true)
              setValidated(true)
              myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
            } else{
              setStepsFunct("step3");  
              setShowerror(false);
              setValidated(false);
            }
          } else if(steps === "step3"){
            if(form.nego_name.validationMessage !== "" || form.job_title.validationMessage !== "" || form.telephone.validationMessage !== "" || form.mobile_no.validationMessage !== "" || form.email.validationMessage !== "" || form.password.validationMessage !== "") {
              setShowerror(true)
              setValidated(true)
              myRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
            } else{ 
              setShowerror(false);
              setValidated(false);
            }

          }

        } else {
          event.preventDefault()
          setLoading(true);

          //console.log("steps", steps)

          if(steps === "step1"){
            setStepsFunct("step2");  
          } else if(steps === "step2"){
            setStepsFunct("step3");  
          } else if(steps === "step3"){

            const formsdata = event.target
            const params = {}
            const post_data = {}
            Object.keys(formsdata).map(key => (
              params[formsdata[key].name] = formsdata[key].checked ? "Yes" : formsdata[key].value)
            )

            //console.log("params =>", params);

            if (params.role === "Agents") {
              setShowerror(false)         
              
              post_data['username'] = params.email;
              post_data['email'] = params.email;
              post_data['password'] = params.password;
              post_data['confirmed'] = true;
              post_data['role'] = UserAgents_RoleID;
              post_data['is_agent'] = true;
              post_data['source'] = "website";
              post_data['approval_status'] = "pending";
              post_data['nego_name'] = params.nego_name;
              post_data['agency_name'] = params.agency_name;
              post_data['branch_name'] = params.branch_name;
              
              post_data['branch_info'] = {"agency_name": params.agency_name, "branch_address": params.branch_address, "branch_area": params.branch_area, "branch_city": params.branch_city, "branch_email": params.branch_email, "branch_name": params.branch_name, "branch_telephone": params.branch_phone, "branch_postcode": params.branch_postcode, "branch_website": params.branch_website, "is_receive_letting_list": params.branch_lettings_list, "is_receive_disposal_list": params.branch_sales_disposal_list};

              post_data['contact_info'] = {"nego_name": params.nego_name, "agent_type": params.agent_type, "job_title": params.job_title, "telephone": params.telephone, "mobile_no": params.mobile_no, "contact_lettings_list": params.contact_lettings_list, "contact_sales_disposal_list": params.contact_sales_disposal_list, "receive_event_info": params.receive_event_info,  "terms_and_conditions": params.terms_and_conditions, "role_id": UserAgents_RoleID, "role": params.role};

              //console.log("post_data ==> ", post_data);
              setStepsFunct("step1");  
              handleAuth(post_data);

            } else {
              setLoading(false);
              setError("Invalid Role!")
              setShowerror(true)
            }

          }
          
        }
    }

    let back_steps = steps === "step2" ? "step1" : steps === "step3" ? "step2" : "";

    return (
        <div className="registration-screen-wrapper">
            <Container>
                <div className="registration-card">
                    <p className="steps">{RegisterSteps[steps].steps_text} 

                      {back_steps && 
                        <a href="javascript:;" onClick={() =>setStepsFunct(back_steps)} className="back_cls">&lt; back</a>
                      }
                    </p>
                    <h1 className="general-heading-h3">{RegisterSteps[steps].title}</h1>

                    <div ref={myRef}></div>
                    {showerror && (
                        <div className="alert alert-danger mt-4 mb-0">
                            {error}
                        </div>
                    )}

                    {showsuccess && (
                        <div className="alert alert-success mt-4 mb-0">
                            {success}
                        </div>
                    )}

                    <div className="form-wrapper">
                        <Form enctype="multipart/form-data"
                            action="/thank-you/"
                            method="post"
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}>
                            <input type="hidden" name="form_type" value={"Agent Registeration"}/>
                            <input type="hidden" name="role" value="Agents" />

                            {/* ===== Steps 1 ==== */}
                            <div className={`${steps === "step1" ? "showStep" : "hideStep" }`}>
                              <Step1 setSteps={setStepsFunct} handlechange={handlechange} />
                            </div>

                            {/* ===== Steps 2 ==== */}
                            <div className={`${steps === "step2" ? "showStep" : "hideStep" }`}>
                              <Step2 setSteps={setStepsFunct} handlechange={handlechange}/>
                            </div>

                            {/* ===== Steps 3 ==== */}
                            <div className={`${steps === "step3" ? "showStep" : "hideStep" }`}>
                              <Step3 setSteps={setStepsFunct} handlechange={handlechange}/>
                            </div>

                        </Form>
                    </div>

                    {steps === "step3" &&
                      <div className="terms-conditions">
                          <span className="no-decoration">
                              By clicking register, you agree to our <Link to={"/"+CustomLinks.terms_conditions+"/"}>Terms and Conditions</Link>
                          </span>
                      </div>
                    }
                    
                </div>
            </Container>
        </div>
    );
};

export default RegistrationScreen;
