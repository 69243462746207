import React, {useState, useEffect} from "react"
import { Link, navigate } from "@reach/router";

// Components
import Layout from "../components/layout"
import {SEODetails} from "../components/common/static_seo"
import RegistrationScreen from "layout/Registration/RegistrationScreen"
import {isLoggedIn} from "../services/auth"
import {CustomLinks} from "../components/common/menuUtils"

const Register = () => {
    const [isAuth, setIsAuth] = useState(isLoggedIn());

    useEffect(() => {
        if(isAuth){
            navigate('/'+CustomLinks.dashboard+'/')
        } else{
            navigate('/'+CustomLinks.register+'/')
        }
    },[isAuth]);

    return (
        <Layout variant={""} Meta_Title={SEODetails.register.title} Meta_Description={SEODetails.register.description}>
            <RegistrationScreen />
        </Layout>
    )
}

export default Register
