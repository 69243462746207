import React, {useState, useEffect} from 'react';

// Components
import CustomTextField from "components/CustomTextField";
import CustomButton from "components/CustomButton";
import CustomDropDown from "components/CustomDropdown";
import CustomRadioButton from "components/CustomRadioButton";
import CustomCheckbox from "components/CustomCheckbox";

const lettingsListOptions = [
    'Yes',
    'No'
];

const Step2 = (props) => {
    return (
        <>
            <div className="form-fields-wrapper">
                <CustomTextField
                    type="text"
                    name="branch_address"
                    label="ADDRESS *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="branch_area"
                    label="TOWN / AREA *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="branch_city"
                    label="CITY / COUNTY *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="branch_phone"
                    label="TELEPHONE *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="email"
                    name="branch_email"
                    label="EMAIL *"
                    defaultValue=""
                    required={true}
                    handlechange={props.handlechange}
                />
                <CustomTextField
                    type="text"
                    name="branch_website"
                    label="WEBSITE"
                    defaultValue=""
                    handlechange={props.handlechange}
                />
                
                <CustomRadioButton
                    isInline
                    variant="green-radio"
                    name="branch_lettings_list"
                    label="Receive Lettings List to the office email address"
                    options={ lettingsListOptions }
                    defaultOption={ lettingsListOptions[ 0 ] }
                    handlechange={props.handlechange}
                // onChangeCallback={ onChange }
                />
                <CustomRadioButton
                    isInline
                    variant="green-radio"
                    name="branch_sales_disposal_list"
                    label="Receive Sales Disposal List to the office email address"
                    options={ lettingsListOptions }
                    defaultOption={ lettingsListOptions[ 0 ] }
                    handlechange={props.handlechange}
                // onChangeCallback={ onChange }
                />
            </div>
            <div>
                <CustomButton
                    variant="green"
                    value="NEXT STEP"
                    type="submit"
                    //onClick={() => props.setSteps('step3')}
                />
            </div>
        </>
    );
};

export default Step2;
